import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
export default {
    init() {
        //获取数据源
        apis.warehouseInSource().then(res => {
            if (tools.msg(res, this)) {
                for (let i in res.data) {
                    if (res.data[i]) {
                        this.$set(this.source, i, res.data[i]);
                    } else {
                        this.$set(this.source, i, []);
                    }
                }
            }
        }).catch(err => {
            tools.err(err, this);
        });
        //如果是编辑，则获取详情
        let id = this.$route.query.id;
        if (id > 0) {
            //查看详情禁止编辑
            if (this.$route.query.read == 1) {
                this.isRead = true;
            }
            apis.warehouseInDetail({
                id: id
            }).then(res => {
                if (tools.msg(res, this)) {
                    this.initData(res.data);
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }
    },
    initData(data) {
        let goods = [];
        data.goods_info.forEach((item, key) => {
            for (let i in item) {
                if (i === 'price' || i === 'subtotal') {
                    item[i] = tools.setPrice(item[i]);
                }
            }
            item.sum = item.subtotal;
            item.out = item.num - item.actual;
            item.retail_price = item.price;
            item.id = item.details_id;
            item.name = item.goods_name;
            item.unit = item.goods_unit_name;
            item.specification_code = item.product_code;
            item.pic = item.specifications_pic;
            item.dispose_info = item.specifications.join(',');

            goods.push(item);
        });
        this.goodsList = goods;

        this.saveData = {
            user_id: tools.me(),
            in_date: tools.now()
        }

        //展示数据
        this.showData = data;
        this.changeSum();
    },
    changeSum() {
        this.sumData.sumNum = 0;
        this.goodsList.forEach((item, key) => {
            this.sumData.sumNum += item.num;
        });
    },
    cancel() {
        history.back();
    },
    exportData() {

    },
    // 获取上传的地址
    uploadFile(file) {
        if (file.code === 200) {
            this.enclosure.push(file.font);
        } else {
            this.$message({
                type: 'error',
                message: file.msg
            });
        }
    },

    remove() {
        let id = this.$route.query.id;
        this.$confirm('确定要删除吗?', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            apis.purchaseDelete({ id: id }).then((info) => {
                if (info.code == 200) {
                    this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });

                    this.$router.push({
                        path: "/purchase"
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: info.msg
                    });
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    },
    copyRecord() {
        let id = this.$route.query.id;
        this.$router.push({
            path: '/purchase/add',
            query: {
                id: id,
                is_copy: 1
            }
        });
    },
    edit() {
        let id = this.$route.query.id;
        this.$router.push({
            path: '/purchase/add',
            query: {
                id: id
            }
        });
    },
    warehouseIn() {
        let id = this.$route.query.id;
        this.saveData.id = id;
        //转换列表
        // 商品信息
        let goods = [];
        if (!this.saveData.user_id) {
            this.$message({
                type: 'error',
                message: '请选择一个经手人'
            });
            return;
        }

        //附件信息
        let enclosure = [];
        this.goodsList.forEach((item, key) => {
            let obj = {
                goods_specifications_id: item.goods_specifications_id,
                actual: item.out,
            };
            goods.push(obj);
        });
        this.enclosure.forEach((item, key) => {
            enclosure.push(item);
        });
        //
        this.saveData.goods = JSON.stringify(goods);
        this.saveData.enclosure = JSON.stringify(enclosure);

        //
        apis.warehouseInAction(this.saveData).then(res => {
            if (tools.msg(res, this, 1)) {
                history.back();
            }
        }).catch(err => {
            tools.err(err, this);
        });


    },
}